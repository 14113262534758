import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { memoize } from 'lodash';
import { smartCropImages } from 'config/features';
import AltContainer from 'alt-container';
import Measure from 'react-measure';
import { getManifestImageHref } from 'selectors/itemImage';
import { getManifest } from '../selectors/tiles';
import TilesStore from '../stores/TilesStore';
import ProviderStore from '../stores/ProviderStore';
import NormalTile from '../components/tiles/NormalTile';
import { providerById } from '../selectors/providers';
import { createItemUriFromManifest } from '../helpers/prettyUrl';

const ALLOWED_ASPECT_RATIO_DIFFERENCE = 5;

const resolverForImages = ({ manifest, width, height }) => `${manifest.id}-w${width}-h${height}`;

const getTileBackground = memoize(({ manifest, width, height }) => {
  const maxAspectRatio = width / height + ALLOWED_ASPECT_RATIO_DIFFERENCE;

  const smartCropOptions = {
    width: Math.round(width * 1.05),
    height: Math.round(height * 1.05),
  };

  return getManifestImageHref(manifest, {
    smartCrop: smartCropImages,
    smartCropOptions,
    criteria: {
      minWidth: width,
      minHeight: height,
      maxAspectRatio,
    },
  });
}, resolverForImages);

const getTileImage = memoize(({ manifest, width }) => {
  const smartCropOptions = {
    width: Math.round(width * 1.05),
  };

  return getManifestImageHref(manifest, {
    smartCrop: smartCropImages,
    smartCropOptions,
    criteria: {
      minWidth: width,
    },
  });
}, resolverForImages);

class ManifestContainer extends PureComponent {
  constructor() {
    super();

    this.state = {
      width: -1,
      height: -1,
    };
  }

  handleMeasure = ({ bounds }) => {
    const { height, width } = bounds;

    this.setState({ height, width });
  };

  // Disable eslint becouse of a false negative
  renderContainer = ({ tilesState, providerState }) => {
    // eslint-disable-line react/prop-types
    const { itemId, analytics } = this.props;

    if (!tilesState.tiles.get(itemId)) {
      return null;
    }

    const manifest = getManifest(tilesState.tiles, itemId);
    const itemDetails = tilesState.tiles.get(itemId);
    const providerId = manifest.provider.id;
    const provider = providerById(providerState, providerId);

    const { width, height } = this.state;

    const portraitPhoto = getTileBackground({
      manifest,
      width,
      height,
    });

    const photo =
      !portraitPhoto &&
      getTileImage({
        manifest,
        width,
      });

    return (
      <Measure bounds onResize={this.handleMeasure}>
        {({ measureRef }) => (
          <span ref={measureRef}>
            <NormalTile
              body={manifest.body}
              template={provider.templates.tile}
              providerName={provider.name}
              providerId={provider.id}
              itemId={itemId}
              showReadCheckBox={itemDetails.opened && itemDetails.item_purchased}
              readerUrl={createItemUriFromManifest(manifest)}
              heartText={String(itemDetails.post_count)}
              photo={portraitPhoto || photo}
              isPortrait={!!portraitPhoto}
              analytics={analytics}
            />
          </span>
        )}
      </Measure>
    );
  };

  render() {
    return (
      <AltContainer
        stores={{
          tilesState: TilesStore,
          providerState: ProviderStore,
        }}
        render={this.renderContainer}
      />
    );
  }
}

ManifestContainer.propTypes = {
  itemId: PropTypes.string.isRequired,
  analytics: PropTypes.shape({
    internal_location: PropTypes.string,
  }).isRequired,
};

export default ManifestContainer;
