import { history } from 'byebye';
import React from 'react';
import Auth from 'controllers/auth';
import DialoguesController from 'controllers/dialogues';
import SignupManager from 'managers/signup';

function redirectTo(url) {
  setTimeout(() => {
    history.navigate(url, { trigger: true, replace: true });
  });
  return <span />;
}

export function rerouteAuthUser(action) {
  const url = ['/user', Auth.getUser().id, action].filter(path => path).join('/');
  return redirectTo(url);
}

/**
 * verified account dialogue. shown after a signup
 */
export function openVerifiedDialogue() {
  SignupManager.getSignUpRewards(Auth.getUser().id).then(rewards => {
    DialoguesController.openUserVerified(rewards);
  });
}
