// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@media screen and (min-width: 600px){.frame-1fBDD{margin-bottom:0;padding-bottom:0}}.frame-1fBDD:after{content:' ';position:absolute;left:0;bottom:0;right:0;pointer-events:none;height:15%;background-image:linear-gradient(-180deg, rgba(0,0,0,0) 0%, #000 97%)}.frame-1fBDD:hover .image-2TefZ{transition:transform 0.3s ease-out;transform:scale(1.02)}.frame-1fBDD:hover .image-2TefZ:before{opacity:0.66}.image-2TefZ{position:absolute;background:center no-repeat;background-size:cover;pointer-events:none;z-index:0;top:0;left:0;bottom:0;right:0;transition:transform 0.8s ease-out}.image-2TefZ:before{content:' ';position:absolute;top:0;left:0;bottom:0;right:0;pointer-events:none;background-image:linear-gradient(rgba(40,50,65,0.25) 0%, rgba(40,50,65,0.5) 100%);transition:opacity 0.7s ease-out}.header-ImcJs{z-index:1;margin:0 0 25px}.social-3s-PL{margin:10px 0 40px;color:#fff}.bottom-1cFFl{position:relative;flex:0 1 auto;max-height:50%;overflow:hidden;z-index:0;margin-bottom:-25px}.bottom-1cFFl *{color:white !important;border-color:white !important}\n", ""]);
// Exports
exports.locals = {
	"frame": "frame-1fBDD",
	"image": "image-2TefZ",
	"header": "header-ImcJs",
	"social": "social-3s-PL",
	"bottom": "bottom-1cFFl"
};
module.exports = exports;
