// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".normalTileWrapper-nEFOS{width:100%;max-height:100%;padding:10px 15px}@media screen and (min-width: 600px){.normalTileWrapper-nEFOS{padding:0;margin-right:20px;width:350px;height:740px}}\n", ""]);
// Exports
exports.locals = {
	"normalTileWrapper": "normalTileWrapper-nEFOS"
};
module.exports = exports;
