import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { translate } from 'instances/i18n';
import Analytics from 'instances/analytics';
import AvatarImage from 'components/AvatarImage';
import FollowButton from 'components/buttons/Follow';
import { DEFAULT_AVATAR, STAFFPICKS } from 'app-constants';

const dummy = document.createElement('div');
const CSSMaskSupport =
  dummy.style['mask-size'] !== undefined || dummy.style['-webkit-mask-size'] !== undefined;

export default class ProfileTile extends React.Component {
  static propTypes = {
    profile: PropTypes.object.isRequired,
  };

  _onClickItems() {
    Analytics.track('View Bought', this._getAnalytics());
  }

  _getAnalytics() {
    return {
      type: 'profile',
      user_id: this.props.profile.id,
    };
  }

  _renderSuperCircleAvatar() {
    const url = this.props.profile.getAvatarHref() || DEFAULT_AVATAR;

    const avatarStyle = {
      background: `url(${url}) 50% 50%`,
      backgroundSize: 'cover',
    };

    // The img element is not visible, but this was users can save the avatar like it is a normal
    // image instead of a background image
    return <div className="supercircle-user-avatar" style={avatarStyle} />;
  }

  _renderAvatar() {
    if (this.props.profile.isChannel) {
      return null;
    }

    // Backwards compatibilty
    if (CSSMaskSupport) {
      return this._renderSuperCircleAvatar();
    }

    return (
      <div className="user-avatar">
        <AvatarImage src={this.props.profile.getAvatarHref()} />
      </div>
    );
  }

  _renderFollowButtonOrReads() {
    const profile = this.props.profile;
    if (profile.id === 'blendle') {
      return;
    }

    if (profile.isChannel) {
      return (
        <div className="lnk button-area">
          <div className="button-holder">
            <FollowButton user={profile} isProfile analytics={this._getAnalytics()} />
          </div>
        </div>
      );
    }

    return (
      <a
        href={`/user/${profile.id}/items`}
        title={`${translate('user.labels.items')} (${profile.get('reads')})`}
        onClick={this._onClickItems.bind(this)}
        className="lnk lnk-items"
      >
        <span className="amount">{profile.getFormattedReads() || '0'}</span>
        <span className="caption">{translate('user.captions.read')}</span>
      </a>
    );
  }

  _renderFollowers() {
    const profile = this.props.profile;

    // hide the followers count at the staffpicks, so journalists can't find out how much users we have
    if (Object.values(STAFFPICKS).includes(profile.id)) {
      return;
    }

    if (!profile.isChannel) {
      return;
    }

    return (
      <span className="lnk lnk-followers">
        <span className="amount">{profile.getFormattedFollowers()}</span>
        <span className="caption">{translate('user.captions.followers')}</span>
      </span>
    );
  }

  _renderBio() {
    if (!this.props.profile.get('text')) {
      return;
    }
    return (
      <p
        className="user-description"
        dangerouslySetInnerHTML={{ __html: this.props.profile.getBioHTML() }}
      />
    );
  }

  render() {
    const profile = this.props.profile;

    const containerClassName = classNames({
      'v-tile': true,
      'v-profile-tile': true,
      'tile-profile': true,
      's-success': true,
      'channel-profile': profile.isChannel,
      [`user-id-${profile.id}`]: true,
    });

    const titleClassName = classNames({
      'user-name': true,
      'v-channel-name': profile.isChannel,
      channel: profile.isChannel,
      [`channel-${profile.id}`]: profile.isChannel,
    });

    return (
      <div className={containerClassName}>
        <div className="tile-container">
          {this._renderAvatar()}

          <h2 className={titleClassName}>{profile.get('full_name')}</h2>

          {this._renderBio()}

          <div className="properties">
            {this._renderFollowButtonOrReads()}
            {this._renderFollowers()}
          </div>
        </div>
      </div>
    );
  }
}
