// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".acquiredBackdrop-1cF8Z{position:absolute;pointer-events:none;overflow:initial;width:85px;height:85px;top:20%;right:0;display:flex;align-items:center;z-index:1}.acquiredIcon-3I59d{color:white;height:18px;margin-left:50%}.acquiredBackdropShape-3HUO9{z-index:-1;width:100%;height:100%;top:0;right:-50%;transform:rotate(45deg)}\n", ""]);
// Exports
exports.locals = {
	"acquiredBackdrop": "acquiredBackdrop-1cF8Z",
	"acquiredIcon": "acquiredIcon-3I59d",
	"acquiredBackdropShape": "acquiredBackdropShape-3HUO9"
};
module.exports = exports;
