// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".header-o0ZRc{margin:0 0 25px}.backdrop-1n7j5{background-color:linear-gradient(47deg, #ff6255 0%, #ff4259 56%, #ff0e61 100%)}.social-28Adp{position:absolute;top:15px;left:12px;color:#fff;z-index:1}@media screen and (min-width: 600px){.social-28Adp{left:25px}}.image-1-N8l{position:relative;margin:20px -25px 0;max-height:25vh;overflow:hidden}@media screen and (min-width: 600px){.image-1-N8l{max-height:33vh}}.image-1-N8l:after{content:' ';position:absolute;top:0;left:0;right:0;height:50%;pointer-events:none;background-image:linear-gradient(0deg, rgba(40,50,65,0) 0%, rgba(40,50,65,0.3) 100%)}.bottom-2Hjt0{flex:0 1 auto;max-height:100%;overflow:hidden;display:flex;z-index:0}\n", ""]);
// Exports
exports.locals = {
	"header": "header-o0ZRc",
	"backdrop": "backdrop-1n7j5",
	"social": "social-28Adp",
	"image": "image-1-N8l",
	"bottom": "bottom-2Hjt0"
};
module.exports = exports;
