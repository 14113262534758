import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import AltContainer from 'alt-container';
import Toast from 'components/Toast';
import TilesStore from 'stores/TilesStore';
import { getPostCount } from 'selectors/tiles';

class ToastContainer extends PureComponent {
  static propTypes = {
    itemId: PropTypes.string.isRequired,
    headlineToastEnabled: PropTypes.bool,
    itemReasonDisabled: PropTypes.bool,
  };

  _renderToast = ({ tilesState }) => {
    const postCount = getPostCount(tilesState.tiles, this.props.itemId);

    return (
      <div className="post-container">
        <Toast postCount={postCount} />
      </div>
    );
  };

  render() {
    return <AltContainer stores={{ tilesState: TilesStore }} render={this._renderToast} />;
  }
}

export default ToastContainer;
