// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".cardFrame-1Kpm3{position:relative;box-shadow:0 1px 3px 0 rgba(0,0,0,0.1),0 0 0 1px rgba(0,0,0,0.03);background-color:white;border-radius:3px;font-family:\"GT-Walsheim\",sans-serif;width:100%;height:100%}.resetLinkStyles-1jJSk{text-decoration:none;color:#333;height:100%}.article-cZ1KO{padding:15px 25px 25px;position:relative;display:flex;flex-direction:column;justify-content:space-between;height:100%;max-height:410px;overflow:hidden}@media screen and (min-width: 600px){.article-cZ1KO{max-height:none}}.dropdown-3F1R9{position:absolute;right:15px;top:15px;z-index:2}\n", ""]);
// Exports
exports.locals = {
	"cardFrame": "cardFrame-1Kpm3",
	"resetLinkStyles": "resetLinkStyles-1jJSk",
	"article": "article-cZ1KO",
	"dropdown": "dropdown-3F1R9"
};
module.exports = exports;
