// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".socialRow-XKlba{align-items:center;margin:0 0 20px}.iconOuter-c5sB6{height:25px;width:25px;border-radius:50%;margin:0 5px 0 0}.heartOuter-Es2GD{background-color:#ff1060}.heartIcon-1dQNT{height:13px;margin:7px 5px;color:#fff}\n", ""]);
// Exports
exports.locals = {
	"socialRow": "socialRow-XKlba",
	"iconOuter": "iconOuter-c5sB6",
	"heartOuter": "heartOuter-Es2GD",
	"heartIcon": "heartIcon-1dQNT"
};
module.exports = exports;
