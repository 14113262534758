import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Columns } from '@blendle/lego';
import ItemPriceContainer from 'containers/ItemPriceContainer';
import ItemCreditsPriceContainer from 'containers/ItemCreditsPriceContainer';
import styledModule from 'helpers/styledModule';
import ProviderLogo from '../../../ProviderLogo';
import CSS from './CardHeader.scss';

const Header = styledModule(Columns)(CSS.cardHeader);
const StyledProviderLogo = styledModule(ProviderLogo)(CSS.logo);
const StyledItemCreditsPriceContainer = styledModule(ItemCreditsPriceContainer)(CSS.itemPrice);
const StyledItemPriceContainer = styledModule(ItemPriceContainer)(CSS.itemPrice);

const CardHeader = memo(({ providerId, providerName, itemId, purchased, lightColors }) => (
  <Header>
    <StyledProviderLogo
      logoType={lightColors ? 'white' : 'normalCrop'}
      provider={{ id: providerId, name: providerName }}
    />
    {!purchased && (
      <>
        <StyledItemCreditsPriceContainer itemId={itemId} />
        <StyledItemPriceContainer itemId={itemId} color={lightColors ? 'white' : 'midnight'} />
      </>
    )}
  </Header>
));

export default CardHeader;

CardHeader.propTypes = {
  providerName: PropTypes.string.isRequired,
  providerId: PropTypes.string.isRequired,
  itemId: PropTypes.string.isRequired,
  purchased: PropTypes.bool.isRequired,
  lightColors: PropTypes.bool,
};

CardHeader.defaultProps = {
  lightColors: false,
};
