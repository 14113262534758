import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import RecommendCount from 'components/RecommendCount';

class Toast extends PureComponent {
  static propTypes = {
    postCount: PropTypes.number.isRequired,
  };

  render() {
    return (
      <div className="v-toast" ref="toast">
        <RecommendCount amount={this.props.postCount} />
      </div>
    );
  }
}

export default Toast;
