// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".cardHeader-17NAj{justify-content:space-between;margin:0 30px 0 0}.logo-2l3Te{height:35px}.itemPrice-32QQ7{margin:10px 0 0}\n", ""]);
// Exports
exports.locals = {
	"cardHeader": "cardHeader-17NAj",
	"logo": "logo-2l3Te",
	"itemPrice": "itemPrice-32QQ7"
};
module.exports = exports;
