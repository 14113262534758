import { INTERNAL_LOCATION } from 'app-constants';

export const getInternalLocation = (timelineName) => {
  switch (timelineName) {
    case 'pins':
      return INTERNAL_LOCATION.TIMELINE_PINS;
    case 'user':
      return INTERNAL_LOCATION.TIMELINE_USER;
    case 'userArchive':
      return INTERNAL_LOCATION.TIMELINE_ARCHIVE;
    case 'trending':
      return INTERNAL_LOCATION.TIMELINE_TRENDING;
    case 'channel':
      return INTERNAL_LOCATION.TIMELINE_CHANNEL;
    default:
      return undefined;
  }
};
