/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React, { useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { STATUS_PENDING, STATUS_ERROR } from 'app-constants';
import Analytics from 'instances/analytics';
import SegmentAnalytics from 'instances/segmentAnalytics';
import TimelineTiles from './TimelineTiles';
import { getInternalLocation } from '../helpers/internalLocation';

const Timeline = memo(({ timelineStatus, timeline, items, profile, active, onFetchNextItems }) => {
  useEffect(() => {
    if (active) {
      const properties = {
        timeline: timeline.name,
        filter: timeline.options.details,
        internal_location: getInternalLocation(timeline.name),
      };

      Analytics.track('View Timeline', properties);
      SegmentAnalytics.page({ pageName: 'Timeline', properties });
    }
  }, [active]);

  return (
    <div className="v-module v-timeline v-module-content s-success">
      {timelineStatus === STATUS_ERROR ? (
        <p>Something went wrong...</p>
      ) : (
        <TimelineTiles
          loading={timelineStatus === STATUS_PENDING}
          allowEmpty={timeline.name.startsWith('user')}
          timeline={timeline}
          items={items}
          profile={profile}
          active={active}
          onNearEnd={onFetchNextItems}
        />
      )}
    </div>
  );
});

Timeline.propTypes = {
  active: PropTypes.bool,
  profile: PropTypes.object, // user or channel profile model
  timeline: PropTypes.object,
  timelineStatus: PropTypes.number,
  items: PropTypes.array.isRequired,
  onFetchNextItems: PropTypes.func,
};

export default Timeline;
