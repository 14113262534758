import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { STATUS_ERROR, NOT_FOUND, INTERNAL_LOCATION } from 'app-constants';
import { history } from 'byebye';
import Auth from 'controllers/auth';
import BrowserEnv from 'instances/browser_environment';
import TilePane from 'components/TilePane';
import Tile from 'components/Tile';
import ProfileTile from 'components/tiles/ProfileTile';
import Measure from 'react-measure';
import Error from 'components/Application/Error';
import Select from 'components/Select';
import { getTrendingFilters } from 'managers/timeline';
import NormalTileContainer from 'containers/NormalTileContainer';
import { getItemId } from 'selectors/item';
import NormalTileWrapper from './NormalTileWrapper';
import LegacyTimelineTile from './LegacyTimelineTile';
import NoPins from './NoPins';
import CSS from './TimelineTiles.scss';
import { getInternalLocation } from '../helpers/internalLocation';

class TimelineTiles extends PureComponent {
  static propTypes = {
    active: PropTypes.bool,
    loading: PropTypes.bool,
    allowEmpty: PropTypes.bool,
    timeline: PropTypes.object.isRequired,
    items: PropTypes.any.isRequired,
    profile: PropTypes.object,
    onNearEnd: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this._analyticsCache = new Map();

    this.state = {
      paneHeight: null,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { timeline } = this.props;

    if (
      this.tilepane &&
      nextProps.timeline.name !== timeline.name &&
      nextProps.timeline.options.details !== timeline.options.details
    ) {
      this.tilepane.scrollTo(0);
    }
  }

  componentWillUnmount() {
    this._analyticsCache = new Map();
  }

  _itemTileAnalytics(name, index) {
    const key = `${name}-${index}`;

    if (!this._analyticsCache.get(key)) {
      this._analyticsCache.set(key, {
        internal_location: getInternalLocation(name),
        position: index,
      });
    }

    return this._analyticsCache.get(key);
  }

  _onNavigateTrending = (value) => {
    history.navigate(`trending/${value}`, { trigger: true });
  };

  _renderItemTile(item, index) {
    const { name } = this.props.timeline;
    const { paneHeight } = this.state;
    const itemId = getItemId(item);

    if (name === 'pins') {
      return (
        <NormalTileWrapper key={`${name}-${itemId}`}>
          <NormalTileContainer
            itemId={itemId}
            tileHeight={paneHeight}
            analytics={{ internal_location: INTERNAL_LOCATION.TIMELINE_PINS }}
          />
        </NormalTileWrapper>
      );
    }

    return (
      <LegacyTimelineTile
        key={`${name}-${itemId}`}
        itemId={itemId}
        timelineName={name}
        isToastDisabled={name === 'user'}
        analytics={this._itemTileAnalytics(name, index)}
        tileHeight={this.state.paneHeight}
      />
    );
  }

  _renderTrendingSelectTile() {
    const options = getTrendingFilters().map(({ trending, label }) => {
      const selected = trending === this.props.timeline.options.details;
      return (
        <option key={trending} value={trending} selected={selected}>
          {label}
        </option>
      );
    });

    return (
      <Tile type="select-trending" key="select-trending" className={CSS.trendingSelectTile}>
        <Select onChange={this._onNavigateTrending}>{options}</Select>
      </Tile>
    );
  }

  _renderProfileTile() {
    if (!this.props.profile.data.id) {
      return null;
    }

    return (
      <Tile type="profile" key="profile">
        <ProfileTile profile={this.props.profile.data} />
      </Tile>
    );
  }

  renderTiles() {
    const timeline = this.props.timeline.name;
    const user = Auth.getUser();

    if (!this.state.paneHeight) {
      return null; // React-measure should always measure the pane height first
    }

    const tiles = this.props.items.map(this._renderItemTile.bind(this));

    // Add an profile tile
    if (this.props.profile) {
      tiles.splice(0, 0, this._renderProfileTile());
    }

    // On mobile, we want to show an dropdown tile on the trending timeline
    if (!this.props.loading && timeline === 'trending' && BrowserEnv.isMobile()) {
      tiles.splice(0, 0, this._renderTrendingSelectTile());
    }

    return tiles;
  }

  _renderEmpty() {
    if (this.props.timeline.name === 'pins') {
      return <NoPins />;
    }

    return <Error type={NOT_FOUND} />;
  }

  render() {
    const { profile, items, loading, allowEmpty, active, onNearEnd, timeline } = this.props;

    if (profile && profile.status === STATUS_ERROR) {
      return this._renderEmpty();
    }

    if (!loading && !items.length && !allowEmpty) {
      return this._renderEmpty();
    }

    return (
      <Measure
        client
        onResize={({ client }) => {
          // Only set the height when larger than 0
          // This is to prevent the timeline from disappearing after a dialog is opened and closed (for instance the settings dialog)
          if (client.height > 0) {
            this.setState({ paneHeight: client.height });
          }
        }}
      >
        {({ measureRef }) => (
          <TilePane
            ref={(c) => (this.tilepane = c)} // eslint-disable-line no-return-assign
            innerRef={measureRef}
            active={active}
            loading={loading}
            onNearEnd={onNearEnd}
            activeTimeline={timeline}
          >
            {this.renderTiles()}
          </TilePane>
        )}
      </Measure>
    );
  }
}

export default TimelineTiles;
