import React from 'react';
import PropTypes from 'prop-types';
import AltContainer from 'alt-container';
import AuthStore from 'stores/AuthStore';
import TimelineStore from 'stores/TimelineStore';
import ChannelsStore from 'stores/ChannelsStore';
import UsersStore from 'stores/UsersStore';
import TilesStore from 'stores/TilesStore';
import ModuleNavigationStore from 'stores/ModuleNavigationStore';
import TimelineActions from 'actions/TimelineActions';
import ChannelActions from 'actions/ChannelActions';
import UserActions from 'actions/UserActions';
import { getTimelineTiles } from 'selectors/tiles';
import { STATUS_OK, NOT_FOUND } from 'app-constants';
import Error from 'components/Application/Error';
import altConnect from 'higher-order-components/altConnect';
import { branch, renderComponent, compose, withProps } from '@blendle/recompose';
import { pipe, prop, path, startsWith } from 'ramda';
import Timeline from './components/Timeline';

function simpleCompare(a, b) {
  return JSON.stringify(a) === JSON.stringify(b);
}

class TimelineContainer extends React.Component {
  static propTypes = {
    timeline: PropTypes.object, // { name, options }
    moduleName: PropTypes.string,
  };

  static defaultProps = {
    moduleName: 'timeline',
  };

  componentDidMount() {
    const { timeline } = this.props;
    this._fetchTimeline(timeline);

    if (timeline.name === 'channel') {
      ChannelActions.fetchChannelDetails(timeline.options.details);
    } else if (timeline.name.startsWith('user')) {
      UserActions.fetchUserDetails(timeline.options.details);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const curTimeline = this.props.timeline;
    const nextTimeline = nextProps.timeline;

    if (!simpleCompare(curTimeline, nextTimeline)) {
      this._fetchTimeline(nextTimeline);
    }

    if (
      nextTimeline.name === 'channel' &&
      curTimeline.options.details !== nextTimeline.options.details
    ) {
      ChannelActions.fetchChannelDetails(nextTimeline.options.details);
    }

    if (
      nextTimeline.name.startsWith('user') &&
      curTimeline.options.details !== nextTimeline.options.details
    ) {
      UserActions.fetchUserDetails(nextTimeline.options.details);
    }
  }

  _fetchTimeline(timeline) {
    const userId = AuthStore.getState().user.id;

    TimelineActions.fetchTimeline(timeline.name, userId, timeline.options);
  }

  _getProfile({ channelsState, authState }, name, profileId) {
    if (!profileId) {
      return;
    }

    if (name === 'channel') {
      return channelsState.details[profileId];
    }
    if (name.startsWith('user')) {
      return authState.details[profileId];
    }
  }

  _onFetchNext() {
    const { activeTimelineKey, timelines } = TimelineStore.getState();
    const timeline = timelines.get(activeTimelineKey);

    if (timeline.status === STATUS_OK && timeline.next) {
      TimelineActions.fetchNextItems(timeline);
    }
  }

  _renderTimeline = ({
    timelineState,
    channelsState,
    authState,
    tilesState,
    moduleNavigationState,
  }) => {
    const { timeline: timelineFromProps } = this.props;

    const { activeModule } = moduleNavigationState;
    const { activeTimelineKey, timelines } = timelineState;
    const timeline = timelines.get(activeTimelineKey);

    if (!timeline || timeline.name === 'kiosk') {
      return null;
    }

    const tiles = getTimelineTiles(tilesState.tiles, timeline.itemIds);
    const profile = this._getProfile(
      { authState, channelsState },
      timeline.name,
      timeline.options.details,
    );

    return (
      <Timeline
        active={activeModule === this.props.moduleName}
        // We have to directly pass the timeline from the props here instead of the timeline from the store
        // Because when switching form one timeline to another, the store timeline is one render behind,
        // since its being updated in the componentDidMount and componentWillReceiveProps lifecycles.
        // This causes the wrong data to be included in our analytics events
        timeline={timelineFromProps}
        timelineStatus={timeline.status}
        items={tiles}
        profile={profile}
        onFetchNextItems={this._onFetchNext}
      />
    );
  };

  render() {
    return (
      <AltContainer
        stores={{
          timelineState: TimelineStore,
          channelsState: ChannelsStore,
          authState: UsersStore,
          tilesState: TilesStore,
          moduleNavigationState: ModuleNavigationStore,
        }}
        render={this._renderTimeline}
      />
    );
  }
}

const startsWithUser = startsWith('user');
const getName = prop('name');
const isTimelineAnUserTimeline = pipe(getName, startsWithUser);
const timelineDetails = path(['options', 'details']);

function mapStateToProps({ timelineState, authState }, props) {
  const { activeTimelineKey, timelines } = timelineState;
  const timeline = timelines.get(activeTimelineKey);

  if (!timeline) {
    return props;
  }

  const { id: userId } = authState.user;
  const isUserOpeningTimelineOfOtherUser =
    isTimelineAnUserTimeline(timeline) && userId !== timelineDetails(timeline);

  return { ...props, isUserOpeningTimelineOfOtherUser };
}

mapStateToProps.stores = { TimelineStore, AuthStore };

const enhance = compose(
  altConnect(mapStateToProps),
  branch(
    prop('isUserOpeningTimelineOfOtherUser'),
    renderComponent(withProps({ type: NOT_FOUND })(Error)),
  ),
);

export default enhance(TimelineContainer);
