import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'instances/i18n';

function RecommendCount(props) {
  const { amount } = props;

  return (
    <div className="v-like" aria-label={translate('recommendation.count', [amount])}>
      {amount}
    </div>
  );
}

RecommendCount.propTypes = {
  amount: PropTypes.number.isRequired,
};

export default RecommendCount;
