import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ManifestDropdownContainer from 'containers/ManifestDropdownContainer';
import Link from 'components/Link';

import CSS from './CardFrame.scss';

export default class CardFrame extends PureComponent {
  render() {
    const {
      providerId,
      readerUrl,
      itemId,
      children,
      className,
      style,
      lightColors,
      analytics,
    } = this.props;

    return (
      <div
        className={`${CSS.cardFrame} item item-manifest provider-${providerId} ${className}`}
        style={style}
      >
        <div className={CSS.dropdown}>
          <ManifestDropdownContainer itemId={itemId} cappuccinoButton={!lightColors} />
        </div>
        <Link className={CSS.resetLinkStyles} href={`/${readerUrl}`} analytics={analytics}>
          <article className={`${CSS.article}`}>{children}</article>
        </Link>
      </div>
    );
  }
}

CardFrame.propTypes = {
  providerId: PropTypes.string.isRequired,
  readerUrl: PropTypes.string.isRequired,
  itemId: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  lightColors: PropTypes.bool,
  analytics: PropTypes.shape({
    internal_location: PropTypes.string,
  }).isRequired,
};

CardFrame.defaultProps = {
  className: '',
  style: {},
  lightColors: false,
};
