import React from 'react';
import { string, object, bool, number } from 'prop-types';
import Tile from 'components/Tile';
import ItemTile from 'components/tiles/ItemTile';

function LegacyTimelineTile({ timelineName, itemId, analytics, isToastDisabled, tileHeight }) {
  return (
    <Tile type="item" key={`${timelineName}-${itemId}`}>
      <ItemTile
        itemId={itemId}
        analytics={analytics}
        toastDisabled={isToastDisabled}
        tileHeight={tileHeight}
      />
    </Tile>
  );
}

LegacyTimelineTile.propTypes = {
  timelineName: string.isRequired,
  itemId: string.isRequired,
  analytics: object.isRequired, // eslint-disable-line react/forbid-prop-types
  isToastDisabled: bool,
  tileHeight: number.isRequired,
};

LegacyTimelineTile.defaultProps = {
  isToastDisabled: false,
};

export default LegacyTimelineTile;
