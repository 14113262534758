// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".header-1o_DG{margin:0 0 40px}.social-BKkaL{margin:10px 0 40px}.dropdown-1iWXG{position:absolute;right:15px;top:15px;z-index:2}.bottom-2H-2K{flex:0 1 auto;max-height:100%;overflow:hidden;display:flex;z-index:0}\n", ""]);
// Exports
exports.locals = {
	"header": "header-1o_DG",
	"social": "social-BKkaL",
	"dropdown": "dropdown-1iWXG",
	"bottom": "bottom-2H-2K"
};
module.exports = exports;
